import React from "react";
import { SiGmail } from "react-icons/si";

import "./TeamMem.css";

export default function TeamMembers() {
  return (
    <div>
      <>
        <div className="container">
          <div style={{ justifyContent: "center" }} className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Douglas_Headshot_Square.jpeg")}
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a
                          target="_blank"
                          href="mailto:dzhu2003@student.ubc.ca"
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/douglas-zhu-518a3a268"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Douglas </h3>
                  <span> Captain</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Caleb_Headshot.png")}
                    alt="Image"
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a
                          target="_blank"
                          href="mailto:creynard@student.ubc.ca"
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/calebreynard"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Caleb </h3>
                  <span> Captain</span>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    src={require("../../images/Thanooshan_Headshot_Square.jpeg")}
                    alt="Image"
                    className="img-fluid"
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a target="_blank" href="mailto:thanoo@student.ubc.ca">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/thanooshan-satheeshkumar"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Thanooshan </h3>
                  <span>Operations Lead</span>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    src={require("../../images/Justin_Headshot_Square.jpeg")} //TODO::
                    alt="Image"
                    className="img-fluid"
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li></li>

                      <li>
                        <a
                          target="_blank"
                          href="mailto:corashao@student.ubc.ca"
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/ngjstn" //TODO::
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Cora </h3>
                  <span>Elec Lead</span>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Caleb_Headshot.png")} //TODO::
                    alt="Image"
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a target="_blank" href="mailto:hsnow@student.ubc.ca">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/calebreynard" //TODO::
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Heidi</h3>
                  <span>Chem Lead</span>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Casey_Headshot_Square.jpeg")}
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a
                          target="_blank"
                          href="mailto:caseyek@student.ubc.ca">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>

                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/caseykrombein"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Casey </h3>
                  <span> Mech Lead</span>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Douglas_Headshot_Square.jpeg")} //TODO::
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a target="_blank" href="mailto:iabro@student.ubc.ca">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="https://ca.linkedin.com/in/douglas-zhu-518a3a268" //TODO::
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Ibaad </h3>
                  <span> Mech Lead</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex flex-wrap flex-[15%] flex-row gap-8 justify-center">
          <div className="team-card bg-white shadow-[0_0_25px_rgba(0,0,0,0.09)] h-[10rem] w-[20rem]">
            <div className="team-member-imfo mt-10">
              <h3>Thanooshan </h3>
              <span> Operatoins Lead</span>
            </div>
          </div>
          <div className="team-card bg-white shadow-[0_0_25px_rgba(0,0,0,0.09)] h-[10rem] w-[20rem]">
            <div className="team-member-imfo mt-10">
              <h3>Cora </h3>
              <span> Elec Lead</span>
            </div>
          </div>
          <div className="team-card bg-white shadow-[0_0_25px_rgba(0,0,0,0.09)] h-[10rem] w-[20rem]">
            <div className="team-member-imfo mt-10">
              <h3>Heidi </h3>
              <span> Chem Lead</span>
            </div>
          </div>
          <div className="team-card bg-white shadow-[0_0_25px_rgba(0,0,0,0.09)] h-[10rem] w-[20rem]">
            <div className="team-member-imfo mt-10">
              <h3>Casey </h3>
              <span> Mech Lead</span>
            </div>
          </div>
          <div className="team-card bg-white shadow-[0_0_25px_rgba(0,0,0,0.09)] h-[10rem] w-[20rem]">
            <div className="team-member-imfo mt-10">
              <h3>Ibaad </h3>
              <span> Mech Lead</span>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
